var isPage = "";
var common = {
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");

		common.resize();
		common.nav();
		page.init();
		common.activeScrl();
		setTimeout(function(){
			$("body").removeClass("disable");
			$(document).off("scroll touchmove").on("scroll touchmove", function(){
				common.scrl();
			});
		});
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	nav: ()=>{
		$("a.menu_opener").off().on("click", function(){
			if(!$html.classList.contains("menu")){
				$html.classList.add("menu");
				setTimeout(function(){
					$html.classList.add("show_menu");
				}, 50);
			}else{
				common.closeMenu();
			}
		});
		$("a.scrolltop").off().on("click", function(){
			common.scrollToPosition(0,1000);
		});
	},
	closeMenu: function(){
		if( $html.classList.contains("show_menu") ){
			$html.classList.remove("show_menu");
			setTimeout(function(){
				$html.classList.remove("menu");
			},600);
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");

		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	makeIO: function(){
		for(var i = 0; i < ioArry.length; i++){
			let option = {
				root 		: null,
				rootMargin 	: ioArry[i].margin,
				threshold 	: ioArry[i].threshold
			}
			let element = $(ioArry[i].target)
			const io = new IntersectionObserver(ioArry[i].change, option);
			$.each(element, function(index, el){
				io.observe(el);
			});
		}
	},
	scrollToPosition: (posY, duration) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: duration,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height();
		//console.log(topD)
		if(topD > 10){
			if(!$html.classList.contains("s_header")){
				$html.classList.add("s_header");
			}
		}else{
			if($html.classList.contains("s_header")){
				$html.classList.remove("s_header");
			}
		}
	},
	activeScrl: function(){
		const boxes = document.querySelectorAll(".a");
		//console.log(boxes);
		const options = {
			root: null, // 今回はビューポートをルート要素とする
			rootMargin: "-33% 0px", // ビューポートの中心を判定基準にする
			threshold: [0, 0.5]	//複数指定可
		};
		//
		observer = new IntersectionObserver(doWhenIntersect, options);
		// それぞれのboxを監視する
		$.each(boxes, function(index, box){
			observer.observe(box);
		});
		function doWhenIntersect(entries) {
			// 交差検知をしたもののなかで、isIntersectingがtrueのDOMを色を変える関数に渡す
			for(let i = 0; i < entries.length; i++){
				let e = entries[i];
				if (e.isIntersecting) {
					activateIndex(e.target);
				}
			}
		}
		function activateIndex(element) {
			let idx = $(".a").index(element);
			var $block = $(".a").eq(idx);
			observer.unobserve(element);
			if(!$block.hasClass("animate")){
				$block.addClass("animate");
				if( $block.hasClass("slideshow") ){
					slideshow.init();
				}
				setTimeout(function(){
					$block.addClass("animated");
				},1000);
			}
		}
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});