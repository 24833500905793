var page = {
	next: "",
	folder: "",
	worksPage: "",
	clickFlag: true,
	isFirst: true,
	init: function(){
		page.checkURL();
		page.nav();
	},
	nav: function(){
		$("ul.list_works li a").off().on("click", function(e){
			e.preventDefault();
			if(page.clickFlag){
				page.clickFlag = false;
				let path = $(this).attr("href");
				page.changeURL(path);
			}
		});
		$("ul.list_works_col3 li a").off().on("click", function(e){
			e.preventDefault();
			if(page.clickFlag){
				page.clickFlag = false;
				let path = $(this).attr("href");
				page.changeURL(path);
			}
		});
		$("a.link_internal").off().on("click", function(e){
			e.preventDefault();
			let $this = $(this);
			let path = $(this).attr("href");
			page.changeURL(path);
		});
		$(".btn_backhome").off().on("click", function(e){
			e.preventDefault();
			let path = $(this).attr("href");
			page.changeURL(path);
		});
		$(".btn_next").off().on("click", function(e){
			e.preventDefault();
			let path = $(this).attr("href");
			page.changeURL(path);
		});
		$(".btn_prev").off().on("click", function(e){
			e.preventDefault();
			let path = $(this).attr("href");
			page.changeURL(path);
		});
		$(".btn_back_works").off().on("click", function(e){
			e.preventDefault();
			let path = $(this).attr("href");
			page.changeURL(path);
		});
		$(".imgs a.btn_more").off().on("click", function(){
			$("li.disable").removeClass("disable");
			$(this).remove();
			setTimeout(function(){
				$("li.hide").removeClass("hide");
			}, 100);
		});
		$("#list_works .more a").off().on("click", function(){
			$(".list_works_col3 li.list_disable").removeClass("list_disable");
			$(this).remove();
			setTimeout(function(){
				$(".list_works_col3 li.list_hide").removeClass("list_hide");
			}, 100);
		});
	},
	changeURL: function(path){
		page.next = path;
		window.history.pushState(null, null, path);
		page.checkURL();
	},
	load: function(){
		$html.classList.add("disable_content");
		common.scrollToPosition(0, 10);
		$.get(page.next, {},function(data){
			var out_html 	= $(data);
			
			setTimeout(function(){
				page.changeContent(out_html).done(function(){
					page.closeLoading().done(function(){
						page.afterChange();
					});
				});
			}, 400);
		});
	},
	changeContent: function(data){
		var defer = $.Deferred();
		var out_html 	= $(data),
			ttl 		= out_html.filter("title")[0].innerHTML,
			$content 	= $("#works_detail .load"),
			content 	= $("#works_detail .load_inner", out_html)[0],
			postTitle 	= $("header", out_html)[0];
		
		$content.html(content);
		document.title = ttl;
		common.scrollToPosition(0, 10);
		if(page.folder === "works"){
			if(page.worksPage != ""){
				$html.classList.add("is_works");
				$html.classList.add("is_detail");
				common.resize();
			}else{
				//works一覧
			}
			defer.resolve();
		}else{
			setTimeout(function(){
				common.resize();
				defer.resolve();
			}, 50);
		}
		return defer.promise();
	},
	afterChange: function(){
		page.clickFlag = true;
		page.nav();
		common.activeScrl();
		page.isFirst = false;
		page.beforePage = page.folder;
	},
	checkURL: function(){
		let	prop 	= location.pathname,
			arry 	= prop.split("/"),
			len 	= arry.length,
			folder 	= arry[1],
			worksPage = arry[len-1];
		page.folder = folder;
		page.worksPage = worksPage;
		console.log(arry, len, folder)
		if(!page.isFirst){
			observer.disconnect();
		}
		if(len === 2){
			if(page.isFirst){
				page.isFirst = false;
				page.checkParam();
				if(folder === ""){
					gMap();
				}
			}else{
				if(folder === "works"){
					//works一覧
					page.showLoading().done(function(){
						page.showWorkList();
					});
				}else{
					//top
					page.showLoading().done(function(){
						page.closeDetail();
						page.checkParam();
					});
				}
				
			}
		}else if(len === 3){
			if(page.isFirst){
				page.isFirst = false;
			}else{
				if(folder === "works"){
					if(worksPage != ""){
						page.showLoading().done(function(){
							page.load();
						});
					}else{
						
					}
				}
			}
		}
	},
	checkParam: function(){
		let param = location.search.substring(1);
		let pair = param.split('&'),
			blk = "";
		let hasParam = false;
		for(var i = 0; pair[i]; i++) {
			var kv = pair[i].split('=');
			if(kv[0] === "block"){
				blk = kv[1];
				hasParam = true;
			}
		}
		if(hasParam){
			let posY = $("#block_" + blk).offset().top;
			common.scrollToPosition(posY, 1000);
		}
	},
	closeDetail: function(){
		window.scrollTo(0,0);
		$html.classList.remove("is_works");
		$html.classList.remove("is_detail");
		page.closeLoading().done(function(){
			page.afterChange();
		});
	},
	showLoading: function(){
		var defer = $.Deferred();
		$html.classList.add("loading");
		setTimeout(function(){
			$html.classList.add("show_loading");
			setTimeout(function(){
				defer.resolve();
			}, 600);
		},50);
		return defer.promise();
	},
	closeLoading: function(){
		var defer = $.Deferred();
		$html.classList.remove("show_loading");
		setTimeout(function(){
			$html.classList.remove("loading");
			defer.resolve();
		}, 600);
		return defer.promise();
	},
	showWorkList: function(){
		common.scrollToPosition(0, 10);
		$html.classList.add("is_works");
		$html.classList.remove("is_detail");
		$html.classList.remove("disable_content");
		page.closeLoading().done(function(){
			page.afterChange();
		});
	}
}